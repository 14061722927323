import decodeJwt from 'jwt-decode';
import {loginApi} from '../config/config';

const ROLE_ADMIN = process.env.REACT_APP_ROLE_ADMIN;

export default {
    login: ({username, password}) => {
        const request = new Request(loginApi, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({
                'Content-Type': 'application/ld+json',
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(({token}) => {
                const decodedToken = decodeJwt(token);

                if (-1 === decodedToken.roles.indexOf(ROLE_ADMIN)) {
                    throw new Error('Access denied!');
                }

                localStorage.setItem('authenticateToken', token);
                localStorage.setItem('userRoles', decodedToken.roles);
            });
    },
    logout: () => {
        localStorage.removeItem('authenticateToken');
        localStorage.removeItem('userRoles');

        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('authenticateToken');
            localStorage.removeItem('userRoles');

            return Promise.reject();
        }

        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('authenticateToken') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        const roles = localStorage.getItem('userRoles');

        return roles ? Promise.resolve(roles) : Promise.reject();
    },
};
