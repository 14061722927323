import React from 'react';
import {List, Datagrid, TextField, ShowButton, EditButton, DeleteButton, FunctionField} from 'react-admin';

class PopupsList extends React.Component {
    render() {
        return (
            <List {...this.props}>
                <Datagrid>
                    <TextField source="title" />
                    <FunctionField label="Status" render={record => record.enable ? 'enabled' : 'disabled'} />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        );
    }
}

export default PopupsList;
