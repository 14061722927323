import React from 'react';
import {Edit, SimpleForm, NumberInput, DateInput, Toolbar, SaveButton, minValue, maxValue} from 'react-admin';

const CashbackEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save and show"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

const percentageValidate = [minValue(0), maxValue(100)];

class CashbackEdit extends React.Component {
    render() {
        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CashbackEditToolbar />}>
                    <NumberInput source="accrual" label="Accrual (0 - 100) %" validate={percentageValidate} />
                    <NumberInput source="usageLimit" label="Usage Limit (0 - 100) %" validate={percentageValidate} />
                    <NumberInput source="validityPeriod" label="Validity Period (Days)" />
                    <DateInput source="startedAt" label="Start Date" />
                    <DateInput source="endedAt" label="End Date" />
                </SimpleForm>
            </Edit>
        );
    }
}

export default CashbackEdit;
