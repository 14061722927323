import React from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    TextInput,
    ImageField,
    ImageInput,
    DateInput
} from 'react-admin';
import TinymceEditor from '../lib/TinymceEditor';
import {locales} from '../../config/config';
import {descriptions} from '../../config/dataProviderParams';

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save and show"
            redirect="show"
            submitOnEnter={true}
        />
    </Toolbar>
);

class BlogPostsEdit extends React.Component {
    onDescriptionChange(content) {
        descriptions[this.locale] = content;
    }

    render() {
        return (
            <Edit {...this.props}>
                <TabbedForm toolbar={<PostEditToolbar />}>
                    <FormTab label="general">
                        <TextInput source="slug" />
                        <ImageField className="main-image main-image--edit" source="images.blog_post_detailed" label="Current main image" addLabel={true} title="Main image" />
                        <ImageInput source="imageBinary" accept="image/*" label="New main image">
                            <ImageField source="src" title="imageBinary.title" />
                        </ImageInput>
                        <DateInput label="Publication date" source="publicationDateStart" />
                    </FormTab>
                    <FormTab label="body">
                        <TextInput source="name" />
                        <TextInput source="metaTitle" />
                        <TextInput source="metaKeywords" />
                        <TextInput source="metaDescription" />
                        <TinymceEditor
                            type="edit"
                            locale="default"
                            onDescriptionChange={this.onDescriptionChange}
                        />
                    </FormTab>
                    {
                        locales.map(locale => {
                            return (
                                <FormTab label={`body ${locale}`} key={`${locale}.${Math.random()}`}>
                                    <TextInput label={`Name ${locale}`} source={`translations[${locale}].name`} />
                                    <TextInput label={`metaTitle ${locale}`} source={`translations[${locale}].metaTitle`} />
                                    <TextInput label={`metaKeywords ${locale}`} source={`translations[${locale}].metaKeywords`} />
                                    <TextInput label={`metaDescription ${locale}`} source={`translations[${locale}].metaDescription`} />
                                    <TinymceEditor
                                        type="edit"
                                        locale={locale}
                                        onDescriptionChange={this.onDescriptionChange}
                                    />
                                </FormTab>
                            )
                        })
                    }
                </TabbedForm>
            </Edit>
        );
    }
}

export default BlogPostsEdit;
