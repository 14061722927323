import React from 'react';
import {
    Edit,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    TextInput,
    ImageField,
    BooleanInput,
    TextField
} from 'react-admin';


const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save and show"
            redirect="show"
            submitOnEnter={true}
        />
    </Toolbar>
);

class CommentsEdit extends React.Component {

    render() {
        return (
            <Edit {...this.props}>
                <TabbedForm toolbar={<PostEditToolbar />}>
                    <FormTab label="general">
                        <TextField source="name" addLabel={true} />
                        <TextField source="content" addLabel={true} />
                        <ImageField className="main-image main-image--edit" source="images" src="url"
                                    label="Images" addLabel={true} title="Main image" />
                        <BooleanInput label="Enable" source="enable" />
                        <BooleanInput label="Show User bought this product" source="bought" />
                    </FormTab>
                    <FormTab label="answer">
                        <TextInput source="answer" multiline fullWidth />
                    </FormTab>
                </TabbedForm>
            </Edit>
        );
    }
}

export default CommentsEdit;
