import React from 'react';
import {
    Create,
    TextInput,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    DateInput,
    Toolbar,
    SaveButton
} from 'react-admin';
import TinymceEditor from '../lib/TinymceEditor';
import {locales} from '../../config/config';
import {descriptions} from '../../config/dataProviderParams';

const PostCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

class BlogPostsCreate extends React.Component {
    onDescriptionChange(content) {
        descriptions[this.locale] = content;
    }

    render() {
        return (
            <Create {...this.props}>
                <TabbedForm toolbar={<PostCreateToolbar />}>
                    <FormTab label="general">
                        <TextInput source="slug" />
                        <ImageInput source="imageBinary" accept="image/*" label="Main image">
                            <ImageField source="src" title="imageBinary.title" />
                        </ImageInput>
                        <DateInput label="Publication date" source="publicationDateStart" />
                    </FormTab>
                    <FormTab label="body">
                        <TextInput source="name" />
                        <TextInput source="metaTitle" />
                        <TextInput source="metaKeywords" />
                        <TextInput source="metaDescription" />
                        <TinymceEditor
                            type="create"
                            locale="default"
                            onDescriptionChange={this.onDescriptionChange}
                        />
                    </FormTab>
                    {
                        locales.map(locale => {
                            return (
                                <FormTab label={`body ${locale}`} key={`${locale}.${Math.random()}`}>
                                    <TextInput label={`Name ${locale}`} source={`translations[${locale}].name`} />
                                    <TextInput label={`metaTitle ${locale}`} source={`translations[${locale}].metaTitle`} />
                                    <TextInput label={`metaKeywords ${locale}`} source={`translations[${locale}].metaKeywords`} />
                                    <TextInput label={`metaDescription ${locale}`} source={`translations[${locale}].metaDescription`} />
                                    <TinymceEditor
                                        type="create"
                                        locale={locale}
                                        onDescriptionChange={this.onDescriptionChange}
                                    />
                                </FormTab>
                            )
                        })
                    }
                </TabbedForm>
            </Create>
        );
    }
}

export default BlogPostsCreate;
