import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    RichTextField,
} from 'react-admin';

class CommentsShow extends React.Component {
    render() {
        return (
            <Show {...this.props}>
                <TabbedShowLayout>
                    <Tab label="view">
                        <TextField source="name" addLabel={true} />
                        <RichTextField
                            source="content"
                            stripTags={false}
                            addLabel={true}
                        />
                    </Tab>

                    <Tab label="resources">
                        <ImageField className="main-image main-image--edit" source="images" src="url"
                                    label="Images" addLabel={true} title="Main image" />
                       </Tab>
                </TabbedShowLayout>
            </Show>
        )
    }
}

export default CommentsShow;
