import React from 'react';
import {Create, SimpleForm, TextInput} from 'react-admin';

class CallbackRequestsCreate extends React.Component {
    render() {
        return (
            <Create {...this.props}>
                <SimpleForm>
                    <TextInput source="phoneNumber" />
                </SimpleForm>
            </Create>
        );
    }
}

export default CallbackRequestsCreate;
