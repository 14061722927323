import {locales} from '../config/config';

function sendTranslationsDataTransform(data, descriptions) {
    let translations = [];

    locales.map(locale => {
        if (!data[locale]) {
            return;
        }

        translations.push({
            locale: locale,
            name: data[locale].name || '',
            metaTitle: data[locale].metaTitle || '',
            metaKeywords: data[locale].metaKeywords || '',
            metaDescription: data[locale].metaDescription || '',
            description: descriptions[locale] || '',
        });
    });

    return translations;
}

function sendShopsTranslationsDataTransform(data) {
    let translations = [];

    locales.map(locale => {
        if (!data[locale]) {
            return;
        }

        translations.push({
            locale: locale,
            name: data[locale].name || '',
            city: data[locale].city || '',
        });
    });

    return translations;
}

function sendTranslationsDataTransformPopup(data, descriptions) {
    let translations = [];

    locales.map(locale => {
        if (!data[locale]) {
            return;
        }

        translations.push({
            locale: locale,
            title: data[locale].title || '',
            text: descriptions[locale] || '',
        });
    });

    return translations;
}

function locationsDataTransform(data) {
    return {'coordinates':{'lat':Number(data.coordinates.lat), 'lng':Number(data.coordinates.lng)}, 'zoom':Number(data.zoom)};
}

function getTranslationsDataTransformer(data) {
    let translations = {};

    Object.values(data).map(value => {
        translations[value.locale] = value;
    });

    return translations;
}

export {
    sendTranslationsDataTransform,
    getTranslationsDataTransformer,
    sendShopsTranslationsDataTransform,
    locationsDataTransform,
    sendTranslationsDataTransformPopup
}
