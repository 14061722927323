const entrypoint = process.env.REACT_APP_API_ENTRYPOINT,
    loginApi = process.env.REACT_APP_API_LOGIN,
    tinymceKey = process.env.REACT_APP_TINYMCE_KEY,
    locales = ['en'],
    entrypointOrder = process.env.REACT_APP_API_ENTRYPOINT_ORDER;

export {
    entrypoint,
    loginApi,
    tinymceKey,
    locales,
    entrypointOrder
}

