import authDataProvider from '../security/authDataProvider';
import {setParamsBlogPosts, setParamsFreeDelivery, setParamsShops,setParamsPrepayment,
    setParamsCard,setParamsComments, setParamsPopup} from './dataProviderParams';

export default {
    ...authDataProvider,
    getList(resource, params) {
        return authDataProvider.getList(resource, params);
    },
    getOne(resource, params) {
        return authDataProvider.getOne(resource, params);
    },
    getOneByOrder(resource, params) {
        return authDataProvider.getOneByOrder(resource, params);
    },
    getMany(resource, params) {
        return authDataProvider.getMany(resource, params);
    },
    async create(resource, params)  {
        switch (resource) {
            case 'blog_posts':
                return authDataProvider.create(resource, await setParamsBlogPosts(params));
            case 'shops':
                return authDataProvider.create(resource, await setParamsShops(params));
            case 'free_delivery':
                return authDataProvider.createOrder(resource, await setParamsFreeDelivery(params));
            case 'prepayment':
                return authDataProvider.createPayment(resource, await setParamsPrepayment(params));
            case 'card':
                return authDataProvider.createCard(resource, await setParamsCard(params));
            case 'popups':
                return authDataProvider.create(resource, await setParamsPopup(params));
            default:
                return authDataProvider.create(resource, params);
        }
    },
    async update(resource, params) {
        switch (resource) {
            case 'blog_posts':
                return authDataProvider.update(resource, await setParamsBlogPosts(params));
            case 'shops':
                return authDataProvider.update(resource, await setParamsShops(params));
            case 'comments':
                if (params.data.answer) {
                    authDataProvider.createAnswer(resource, await setParamsComments(params));
                }
                return authDataProvider.update(resource, await setParamsComments(params));
            case 'popups':
                return authDataProvider.update(resource, await setParamsPopup(params));
            default:
                return authDataProvider.update(resource, params);
        }
    },
};
