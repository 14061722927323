import React from 'react';
import {List, Datagrid, TextField, ShowButton, EditButton,FunctionField,DateField} from 'react-admin';

class CommentsList extends React.Component {
    render() {
        return (
            <List {...this.props} sort={{ field: 'createdAt', order: 'DESC' }} perPage={10}>
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="content" />
                    <FunctionField label="Status" render={record => record.enable === 1 ? 'enabled' : 'disabled'} />
                    <FunctionField label="User bought this product" render={record => record.bought === 1 ? 'Так' : 'Ні'} />
                    <FunctionField label="Product Name" render={record => record.productNameUk} />
                    <DateField source="createdAt" />
                    <DateField source="updatedAt" />
                    <ShowButton />
                    <EditButton />
                </Datagrid>
            </List>
        );
    }
}

export default CommentsList;
