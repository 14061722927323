import React from 'react';
import {List, Datagrid, TextField, ShowButton, EditButton, DeleteButton} from 'react-admin';

class BlogPostsList extends React.Component {
    render() {
        return (
            <List {...this.props}>
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="slug" />
                    <TextField source="id" />
                    <ShowButton />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        );
    }
}

export default BlogPostsList;
