import React from 'react';
import {
    Create,
    TextInput,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton
} from 'react-admin';
import {locales} from '../../config/config';

const PostCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="save"
            redirect="list"
            submitOnEnter={true}
        />
    </Toolbar>
);

class ShopsCreate extends React.Component {
    render() {
        return (

            <Create {...this.props}>

                <TabbedForm toolbar={<PostCreateToolbar />}>
                    <FormTab label="general">
                        <TextInput source="slug" />
                        <TextInput source="name" />
                        <TextInput source="city" />
                        <TextInput source="phoneNumbers" />
                        <TextInput source={"schedule"} />
                        <TextInput source={"location.coordinates.lat"} label={'Coordinates Lat'} />
                        <TextInput source={"location.coordinates.lng"} label={'Coordinates Lng'} />
                        <TextInput source={"location.zoom"} label={'Coordinates Zoom'} />
                    </FormTab>
                    {
                        locales.map(locale => {
                            return (
                                <FormTab label={`${locale}`} key={`${locale}.${Math.random()}`}>
                                    <TextInput label={`Name ${locale}`} source={`translations[${locale}].name`} />
                                    <TextInput label={`City ${locale}`} source={`translations[${locale}].city`} />
                                </FormTab>
                            )
                        })
                    }
                </TabbedForm>
            </Create>
        );
    }
}

export default ShopsCreate;
