import React from 'react';
import dataProvider from '../../config/dataProvider';
import {Create, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="create"
            submitOnEnter={true}
        />
    </Toolbar>
);


class PrepaymentCreate extends React.Component {
    constructor() {
        super();

        this.state = {
            nameAddition: ''
        }
    }
    componentDidMount() {
        this.getPrepaymentText('0f09d529-d9b5-4c31-86f3-1a9fa0bbd9d5','uk').then(result => this.setState({
            nameAddition: (result.data.nameAddition ?
                result.data.nameAddition.replace("Передплата ", "").replace(" грн", "") : ''),
        }))
    }

    getPrepaymentText(id,lang) {
        // replace with whatever your api logic is.
        return dataProvider.getOneByPayment('payment_methods', { id: id },lang);
    }

    render() {
        return (
            <Create {...this.props}>
                <SimpleForm toolbar={<PostEditToolbar />}>
                    <TextInput source="nameAddition" defaultValue={this.state.nameAddition ? this.state.nameAddition : '' }/>
                </SimpleForm>
            </Create>
        );
    }
}

export default PrepaymentCreate;
