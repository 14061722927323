import React from 'react';
import {Show, TabbedShowLayout, Tab, TextField, ImageField, RichTextField} from 'react-admin';

class BlogPostsShow extends React.Component {
    render() {
        return (
            <Show {...this.props}>
                <TabbedShowLayout>
                    <Tab label="view">
                        <TextField source="name" addLabel={true} />
                        <ImageField className="main-image main-image--show" source="images.blog_post_detailed" label="Main Image" addLabel={true} title="Main image" />
                        <RichTextField
                            source="description"
                            stripTags={false}
                            addLabel={false}
                        />
                    </Tab>
                    <Tab label="resources">
                        <TextField source="name" addLabel={true} />
                        <TextField source="id" addLabel={true} />
                        <TextField source="slug" addLabel={true} />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        )
    }
}

export default BlogPostsShow;
