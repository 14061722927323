import React from 'react';
import {Editor} from '@tinymce/tinymce-react';
import {tinymceKey} from '../../config/config';

class TinymceEditor extends React.Component {
    render() {
        return (
            <Editor
                apiKey={tinymceKey}
                init={{
                    height: 500,
                    menubar: 'insert',
                    media_alt_source: false,
                    plugins: [
                        'code media image advlist autolink lists link charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'code image media | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
                    ...this.props.type === 'edit' && {
                        init_instance_callback: editor => {
                            const translations = this.props.record.translations[this.props.locale];
                            const currentData = this.props.locale === 'default' ? this.props.record : translations;
                            if (currentData) {
                                if (currentData.description) {
                                    editor.setContent(currentData.description || '');
                                }
                                if (currentData.text) {
                                    editor.setContent(currentData.text || '');
                                }
                            }

                        }
                    },
                }}
                locale={this.props.locale}
                onEditorChange={this.props.onDescriptionChange}
            />
        )
    }
}

export default TinymceEditor;
