import {fetchUtils} from 'react-admin';
import restProvider from '../config/restProvider';
import {entrypoint,entrypointOrder} from '../config/config';

const httpClient = (url, options = {},lang = 'uk') => {
    const fetchHeaders = {
        'Accept': 'application/ld+json',
        'Accept-Language': `${lang}`,
        'Content-Type': 'application/ld+json',
        Authorization: `Bearer ${window.localStorage.getItem('authenticateToken')}`,
    };

    options.headers = new Headers(fetchHeaders);
    return fetchUtils.fetchJson(url, options);

};

export default restProvider(entrypoint, httpClient,entrypointOrder);
