import React from 'react';
import dataProvider from '../../config/dataProvider';
import {CheckboxGroupInput, Create, SaveButton, SimpleForm, TextInput, Toolbar} from 'react-admin';

const PostEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            label="Save"
            redirect="create"
            submitOnEnter={true}
        />
    </Toolbar>
);


class FreeDeliveryCreate extends React.Component {
    constructor() {
        super();

        this.state = {
            freeSum: '',
            //freeSumApp: '',
            freeSumCourier: '',
            //freeSumCourierApp: '',
            checkboxInitial : []
        }
    }
    componentDidMount() {
        this.getFreeSum('8052e802-ffc0-11e9-841c-0242ac12000c').then(result => this.setState({
            freeSum: (result.data.freeSum ? result.data.freeSum : 0),
            checkboxInitial: result.data.freeSum ?  [...this.state.checkboxInitial, '8052e802-ffc0-11e9-841c-0242ac12000c'] : this.state.checkboxInitial,
        }))
        this.getFreeSum('fb2e96e8-ffc0-11e9-86cc-0242ac12000c').then(result => this.setState({
            freeSumCourier: (result.data.freeSum ? result.data.freeSum : 0),
            checkboxInitial: result.data.freeSum ? [...this.state.checkboxInitial, 'fb2e96e8-ffc0-11e9-86cc-0242ac12000c'] : this.state.checkboxInitial
        }))
    }

    getFreeSum(id) {
        // replace with whatever your api logic is.
        return dataProvider.getOneByOrder('shipping_methods', { id: id });
    }

    render() {
        return (
            <Create {...this.props}>
                <SimpleForm toolbar={<PostEditToolbar />}>
                    <TextInput source="freeSum" defaultValue={this.state.freeSum ? this.state.freeSum : this.state.freeSumCourier }/>
                    <CheckboxGroupInput source="type" choices={[
                        { id: '8052e802-ffc0-11e9-841c-0242ac12000c', name: 'Нова Пошта (відділення)', checked: true },
                        { id: 'fb2e96e8-ffc0-11e9-86cc-0242ac12000c', name: 'Курʼєром', checked: true },
                    ]} initialValue={this.state.checkboxInitial}/>
                </SimpleForm>
            </Create>
        );
    }
}

export default FreeDeliveryCreate;
