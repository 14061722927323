const getCustomUrlByResource = resource => {
    switch (resource) {
        case 'cashback':
            return `_${resource}/settings`;
        case 'order':
            return `_${resource}`;
        default:
            return resource;
    }
};

export default getCustomUrlByResource;