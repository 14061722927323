import React from "react";
import {Show, TabbedShowLayout, Tab, TextField, FunctionField} from 'react-admin';

class ShopsShow extends React.Component {
    render() {
        return (
            <Show {...this.props}>
                <TabbedShowLayout>
                    <Tab label="view">
                        <TextField source="name" addLabel={true} />
                        <TextField source="city" addLabel={true} />
                        <FunctionField label="phoneNumbers" addLabel={true} render={record => `${record.phoneNumbers}`} />
                    </Tab>
                    <Tab label="resources">
                        <TextField source="name" addLabel={true} />
                        <TextField source="id" addLabel={true} />
                        <TextField source="slug" addLabel={true} />
                    </Tab>
                </TabbedShowLayout>
            </Show>
        )
    }
}

export default ShopsShow;
