import React from 'react';
import {List, Datagrid, TextField, DateField, ShowButton, EditButton, DeleteButton} from 'react-admin';

class CashbackList extends React.Component {
    render() {
        return (
            <List {...this.props}>
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="validityPeriod" label={'Validity Period (Days)'} />
                    <DateField source="startedAt" />
                    <DateField source="endedAt" />
                    <ShowButton />
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        );
    }
}

export default CashbackList;