import React from 'react';
import {Show, SimpleShowLayout, TextField, DateField} from 'react-admin';

class CashbackShow extends React.Component {
    render() {
        return (
            <Show {...this.props}>
                <SimpleShowLayout>
                    <TextField source="id" addLabel={true} />
                    <TextField source="accrual" label="Accrual (%)" />
                    <TextField source="usageLimit" label="Usage Limit (%)" />
                    <TextField source="validityPeriod" label="Validity Period (Days)" />
                    <DateField source="startedAt" addLabel={true} />
                    <DateField source="endedAt" addLabel={true} />
                </SimpleShowLayout>
            </Show>
        )
    }
}

export default CashbackShow;